import { Stack } from '@components/atoms/Stack';
import { StoreOpeningHours } from '@features/storepage/types';
import OpeningHoursRow from '../OpeningHoursRow';
import { StoreInfoText } from '../StoreInfo.styles';

const OpeningHoursContent = ({ storeOpeningHours }: { storeOpeningHours: StoreOpeningHours }) => {
  const { openingDays = [], storeFeatureOpeningDays = [] } = storeOpeningHours || {};

  return (
    <Stack $space="nano">
      {openingDays?.length ? (
        <div>
          {openingDays?.map(({ name, openToClosingHours }) => {
            return <OpeningHoursRow key={openToClosingHours} name={name} openToClosingHours={openToClosingHours} />;
          })}
        </div>
      ) : null}
      {storeFeatureOpeningDays?.map(({ storeFeature, openingHours }) => (
        <div key={storeFeature}>
          <StoreInfoText weight="medium">{storeFeature}</StoreInfoText>
          {openingHours.map(({ name, openToClosingHours }) => (
            <OpeningHoursRow key={name} name={name} openToClosingHours={openToClosingHours} />
          ))}
        </div>
      ))}
    </Stack>
  );
};

export default OpeningHoursContent;
