import { Fragment, memo, useId } from 'react';
import { Menu } from '../SideNav.styles';
import SideNavLink from '../SideNavLink/SideNavLink';
import { MenuItem } from '../SideNavLink/SideNavLink.styles';
import { SideNavNode } from '../useSideNavData/useSideNavData';
interface Props {
  link: SideNavNode;
  onClick: (parentIds: Array<string>, id: string) => void;
  activeItems: string[];
  currentlyExpandedCategory: string;
  selectedId: string;
}

const RecursiveList = ({ link, onClick, activeItems, currentlyExpandedCategory, selectedId }: Props) => {
  const expanded = activeItems.includes(link.id);
  const active = selectedId === link.id;
  const hasBorder = currentlyExpandedCategory === link.id;
  const isHighlighted = expanded && link.parentIds.length < 1;

  const contentId = useId();
  return (
    <Fragment key={link.id}>
      <MenuItem $hasBorder={hasBorder} $isHighlighted={isHighlighted} data-testid={link.id}>
        <SideNavLink
          aria-expanded={expanded}
          aria-controls={contentId}
          link={link}
          onClickExpand={onClick}
          expanded={expanded}
          active={active}
        />
        {expanded && !!link.childNodes?.length && (
          <Menu id={contentId}>
            {link?.childNodes?.map((child) => (
              <RecursiveList
                link={child}
                key={child.id}
                onClick={onClick}
                activeItems={activeItems}
                currentlyExpandedCategory={currentlyExpandedCategory}
                selectedId={selectedId}
              />
            ))}
          </Menu>
        )}
      </MenuItem>
    </Fragment>
  );
};

export default memo(RecursiveList);
