import { formatPostalCode } from '@helpers/address';
import {
  OpeningHours,
  StoreFeatures,
  StoreInfoComponentDataOpeningHoursType,
  StoreInfoComponentDataType,
} from '../types';

export const HIDDEN_STORE_FEATURES_IDS = [
  100, // Burek
  59, // Picadeli
  49, // Färskt bröd
  101, // Grill
].map((id) => {
  return `StoreFeatureId-${id.toString().padStart(10, '0')}`;
});

export const rewriteStoreFeatures = (storeFeatures: { [key: string]: string }) =>
  Object.keys(storeFeatures).reduce((obj, id) => {
    if (!HIDDEN_STORE_FEATURES_IDS.includes(id) && storeFeatures[id]) {
      return {
        ...obj,
        [id]: storeFeatures[id],
      };
    }
    return obj;
  }, {});

// formats days, eg: Mån-Fre
const supportedCopyKeys = ['MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY', 'SUNDAY'];
export const getName = (t: (key: string) => string, name1: string = '', name2: string = '') => {
  if (supportedCopyKeys.includes(name1) && supportedCopyKeys.includes(name2)) {
    return `${t(name1)}-${t(name2)}`;
  }
  if (supportedCopyKeys.includes(name1)) {
    return t(name1);
  }
  return name1;
};

// formats opening hours, eg: 9-19
export const getOpeningHours = (openingHours?: string, closingHours?: string) => {
  if (openingHours === '0' && closingHours === '0') {
    return 'Stängt';
  }
  return !!openingHours && !!closingHours ? `${openingHours}-${closingHours}` : undefined;
};

// opening days
export const getOpeningDays = (t: (key: string) => string, mergedOpeningDays: StoreInfoComponentDataOpeningHoursType) =>
  Object.values(mergedOpeningDays).map(({ name2, name1, closingHours, openingHours }) => ({
    name: getName(t, name1, name2),
    openToClosingHours: getOpeningHours(openingHours, closingHours),
  }));

export const getStoreOpeningHours = (
  t: (key: string) => string,
  storeInfo: StoreInfoComponentDataType,
  rewrittenStoreFeatures: StoreFeatures
) => {
  const { showOpeningHours, showFeatureOpeningDays, mergedOpeningDays, mergedFeatureOpeningDaysForWeek } =
    storeInfo || {};

  const shouldShowOpeningHours = !!mergedOpeningDays && showOpeningHours === 'true';
  const shouldShowOpeningDays = !!mergedOpeningDays && showFeatureOpeningDays === 'true';
  const openingDays = shouldShowOpeningHours || shouldShowOpeningDays ? getOpeningDays(t, mergedOpeningDays) : undefined;

  const storeFeatureOpeningDays =
    mergedFeatureOpeningDaysForWeek &&
    Object.entries(mergedFeatureOpeningDaysForWeek)
      // filter out HIDDEN_STORE_FEATURES_IDS
      .filter(([key]) => rewrittenStoreFeatures[key])
      .map(([key, value]) => {
        return {
          storeFeature: rewrittenStoreFeatures[key],
          openingHours: Object.values(value).map(({ name1, name2, openingHours, closingHours }: OpeningHours) => ({
            name: getName(t, name1, name2),
            openToClosingHours: getOpeningHours(openingHours, closingHours),
          })),
        };
      });

  return {
    storeFeatureOpeningDays,
    openingDays,
    visible: !!storeFeatureOpeningDays?.length || !!openingDays?.length,
  };
};

export const getSpecialOpeningDays = (
  t: (key: string) => string,
  specialOpeningDays: StoreInfoComponentDataOpeningHoursType
) => {
  const formattedSpecialOpeningDays =
    specialOpeningDays &&
    Object.values(specialOpeningDays).map(({ name1, name2, openingHours, closingHours }) => ({
      name: getName(t, name1, name2),
      openToClosingHours: getOpeningHours(openingHours, closingHours),
    }));
  return { specialOpeningDays: formattedSpecialOpeningDays, visible: !!formattedSpecialOpeningDays.length };
};

export const getStoreLocation = (storeInfo: StoreInfoComponentDataType) => {
  const { address, geoPoint } = storeInfo.store || {};

  const longitude = geoPoint?.longitude;
  const latitude = geoPoint?.latitude;
  const streetAddress = address?.line1 || '';
  const postalAddress = formatPostalCode(address?.postalCode, address?.town);
  return {
    streetAddress,
    postalAddress,
    mapUrl: longitude && latitude && `https://www.google.com/maps/search/?api=1&query=${latitude},${longitude}`,
    directionsUrl:
      longitude && latitude && `https://www.google.com/maps/dir/?api=1&destination=${latitude},${longitude}`,
    visible: !!streetAddress && !!postalAddress,
  };
};
