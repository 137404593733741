import { StoreInfoText } from '../StoreInfo.styles';
import { OpeningHoursWrapper } from './OpeningsHoursRow.styles';

const OpeningHours = ({
  name,
  openToClosingHours,
  color = 'black',
  direction = 'row',
}: {
  name?: string;
  openToClosingHours?: string;
  color?: 'primary' | 'black';
  direction?: 'column' | 'row';
}) => {
  if (!name) return null;
  return (
    <OpeningHoursWrapper $direction={direction}>
      <StoreInfoText color={color}>{name}</StoreInfoText>
      {openToClosingHours && <StoreInfoText color={color}>{openToClosingHours}</StoreInfoText>}
    </OpeningHoursWrapper>
  );
};

export default OpeningHours;
