import { insetFocusBoxShadow } from '@styles/utils';
import { rem } from 'polished';
import styled, { css } from 'styled-components';

const basePadding = 16;
const increment = 8;

const isHighlightedStyles = css`
  background-color: ${({ theme }) => theme.colors.colorPrimary};
  color: ${({ theme }) => theme.colors.colorWhite};
  @media (hover: hover) {
    &:hover {
      background-color: ${({ theme }) => theme.colors.colorCardinal};
    }
  }
`;

const isSubLevelHighlightedStyles = css`
  background-color: ${({ theme }) => theme.colors.colorLinen};
  @media (hover: hover) {
    &:hover {
      background-color: ${({ theme }) => theme.colors.colorRemy};
    }
  }
`;

export const CategoryWrapper = styled.div<{
  $isHighlighted: boolean;
  $isHighlightedSubLevel: boolean;
}>`
  display: flex;
  justify-content: space-between;
  align-items: center;

  height: ${rem(48)};
  @media (hover: hover) {
    &:hover {
      background-color: ${({ theme }) => theme.colors.colorLinen};
    }
  }
  ${({ $isHighlighted }) => $isHighlighted && isHighlightedStyles};
  ${({ $isHighlightedSubLevel }) => $isHighlightedSubLevel && isSubLevelHighlightedStyles};
`;

export const MenuItem = styled.li<{ $hasBorder: boolean; $isHighlighted: boolean }>`
  border-left: ${({ $hasBorder, theme }) => $hasBorder && `${rem(2)} solid ${theme.colors.colorPrimary}`};
  transition: max-height 0.8s;
  max-height: 0;
  min-height: ${rem(48)};
  overflow: hidden;

  ${({ $isHighlighted }) =>
    $isHighlighted &&
    css`
      &,
      & > ${CategoryWrapper} {
        border-top-left-radius: ${rem(4)};
        border-top-right-radius: ${rem(4)};
      }
      & {
        max-height: ${rem(2000)};
      }
    `};

  ${({ $hasBorder }) =>
    $hasBorder &&
    css`
      & {
        max-height: ${rem(2000)};
      }
    `};

  & > menu > li:last-child {
    border-bottom-left-radius: ${rem(4)};
    border-bottom-right-radius: ${rem(4)};
  }

  & li {
    background-color: ${({ theme }) => theme.colors.colorWhite};
  }
`;
export const LinkContent = styled.a<{ $indentationLvl: number; $active?: boolean }>`
  padding-left: ${({ $indentationLvl }) => ` ${rem($indentationLvl * increment + basePadding)}`};
  text-decoration: none;
  font-weight: ${({ theme }) => theme.fonts.fontWeight.medium};
  color: inherit;
  font-size: ${rem(14)};

  line-height: 1.4;
  flex: 1 0 0;
  height: 100%;
  display: flex;
  align-items: center;
  min-width: 0;
  &:focus-visible {
    ${insetFocusBoxShadow}
  }
  ${({ $active, theme }) => !!$active && `color: ${theme.colors.colorPrimary}`};
`;

export const Ellipsis = styled.span`
  overflow: hidden;
  max-width: 90%;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: block;
`;

export const IconButton = styled.button`
  height: 100%;
  border: none;
  background-color: transparent;
  padding: 0 ${rem(8)};
  &:focus-visible {
    ${insetFocusBoxShadow}
  }
  > svg {
    flex-shrink: 0;
  }
`;
