import SchemaScript from '@components/atoms/SchemaScript';
import { Stack } from '@components/atoms/Stack';
import { StoreInfoCmsData } from '@features/storepage/hooks/useStoreInfoCmsData';
import useTranslation from 'next-translate/useTranslation';
import { ReactNode } from 'react';
import Accordion from '../Accordion';
import Contact from './Contact';
import Location from './Location';
import OpeningHoursContent from './OpeningHoursContent';
import OpeningHoursRow from './OpeningHoursRow';
import StoreFeatures from './StoreFeatures';
import { Container, StoreInfoText } from './StoreInfo.styles';

interface Props {
  storeInfo: StoreInfoCmsData;
  defaultExpanded: boolean;
}

const BoldStoreInfoText = ({ children }: { children: ReactNode }) => (
  <StoreInfoText weight="bold">{children}</StoreInfoText>
);

const StoreInfo = ({ storeInfo, defaultExpanded }: Props) => {
  const { t } = useTranslation('store');
  const {
    storeOpeningHours,
    storeSpecialOpeningDays,
    storeFeatures,
    storeLocation,
    storeContact,
    localBusinessStructuredData,
  } = storeInfo;

  return (
    <Container>
      {!!localBusinessStructuredData ? <SchemaScript structuredData={localBusinessStructuredData} /> : null}

      {storeOpeningHours.visible && (
        <Accordion summary={<BoldStoreInfoText>{t('info->openingHours')}</BoldStoreInfoText>} open={defaultExpanded}>
          <OpeningHoursContent storeOpeningHours={storeOpeningHours} />
        </Accordion>
      )}
      {storeSpecialOpeningDays.visible && (
        <Accordion
          summary={<BoldStoreInfoText>{t('info->specialOpeningHours')}</BoldStoreInfoText>}
          open={defaultExpanded}
        >
          <Stack $space="nano">
            {storeSpecialOpeningDays.specialOpeningDays?.map(({ name, openToClosingHours }) => (
              <OpeningHoursRow key={name} name={name} openToClosingHours={openToClosingHours} direction="column" />
            ))}
          </Stack>
        </Accordion>
      )}

      {storeFeatures.visible && (
        <Accordion summary={<BoldStoreInfoText>{t('info->service')}</BoldStoreInfoText>} open={defaultExpanded}>
          <StoreFeatures storeFeatures={storeFeatures.features} />
        </Accordion>
      )}
      {storeLocation.visible && (
        <Accordion summary={<BoldStoreInfoText>{t('info->getHere')}</BoldStoreInfoText>} open={defaultExpanded}>
          <Location location={storeLocation} />
        </Accordion>
      )}

      {storeContact.visible && (
        <Accordion summary={<BoldStoreInfoText>{t('info->contact')}</BoldStoreInfoText>} open={defaultExpanded}>
          <Contact contactInfo={storeContact} />
        </Accordion>
      )}
    </Container>
  );
};

export default StoreInfo;
