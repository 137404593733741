import Icon from '@components/atoms/Icon';
import { useAppDispatch, useAppSelector } from '@hooks/redux';
import IconMinus from '@public/icons/regularIcons/icon-minus.svg';
import IconPlus from '@public/icons/regularIcons/icon-plus.svg';
import useTranslation from 'next-translate/useTranslation';
import Link from 'next/link';
import { selectSideNavIsOpen, sideNavToggled } from 'redux/slices/sideNav';
import { navigationEvent } from '../../../trackers/assortmentTracker/assortmentTracker';
import { SideNavNode } from '../useSideNavData/useSideNavData';
import { CategoryWrapper, Ellipsis, IconButton, LinkContent } from './SideNavLink.styles';

interface Props {
  link: SideNavNode;
  onClickExpand: (parentIds: Array<string>, id: string) => void;
  expanded: boolean;
  active: boolean;
  'aria-expanded'?: boolean;
  'aria-controls'?: string;
}

const SideNavLink = ({
  link,
  onClickExpand,
  expanded,
  active,
  'aria-controls': ariaControls,
  'aria-expanded': ariaExpanded,
}: Props) => {
  const { url, title, parentIds, childNodes, id, trackingId } = link;
  const { t } = useTranslation('common');
  const handleClickExpand = () => {
    const addOrRemoveParents = expanded ? parentIds : [...parentIds, id];
    const previouslySelected = parentIds.find((category) => category !== id) || '';
    const currentlySelectedTitle = expanded ? previouslySelected : id;

    onClickExpand(addOrRemoveParents, currentlySelectedTitle);
  };
  const ariaLabel = expanded ? t('ariaLabels->collapse', { title }) : t('ariaLabels->expand', { title });

  const isHighlighted = expanded && parentIds.length < 1 && !!childNodes.length;
  const isHighlightedSubLevel = expanded && parentIds.length === 1;
  const hasTextPrimary = active && !isHighlighted;
  const dispatch = useAppDispatch();
  const sideNavIsOpen = useAppSelector(selectSideNavIsOpen);

  const handleLinkClick = () => {
    navigationEvent(trackingId);
    dispatch(sideNavToggled(!sideNavIsOpen));

    if (!!childNodes.length) {
      onClickExpand([...parentIds, id], id);
    }
  };

  return (
    <CategoryWrapper
      $isHighlighted={isHighlighted}
      $isHighlightedSubLevel={isHighlightedSubLevel}
      data-testid="list-item-pane-link"
    >
      <Link legacyBehavior href={url} passHref>
        <LinkContent onClick={handleLinkClick} $indentationLvl={parentIds.length} $active={hasTextPrimary}>
          <Ellipsis>{title}</Ellipsis>
        </LinkContent>
      </Link>
      {!!childNodes?.length && (
        <IconButton
          aria-controls={ariaControls}
          aria-expanded={ariaExpanded}
          onClick={handleClickExpand}
          aria-label={ariaLabel}
          data-testid="list-item-pane-arrow"
        >
          <Icon icon={expanded ? IconMinus : IconPlus} size={20} />
        </IconButton>
      )}
    </CategoryWrapper>
  );
};

export default SideNavLink;
